<template>
  <DocumentLink :type="type" :href="href">{{ label }}</DocumentLink>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      type() {
        return this.getValue("document_type") || "pdf"
      },
      href() {
        return this.getValue("document")
      },
      label() {
        return (
          this.getValue("label") ||
          this.getIngredient("document")?.attachmentName
        )
      },
    },
  }
</script>
